import React from 'react'
import IndexLayout from './index/index'
import PostLayout from './post/index'

type Props = {
  children?: any
  pageContext?: any
  seo?: any
}

const Layout: React.FC<Props> = ({ children, pageContext, seo, disableGoTop }) => {
  if (pageContext && pageContext.layout === "post") {
    return <PostLayout>{children}</PostLayout>
  }
  return <IndexLayout disableGoTop={disableGoTop}>{children}</IndexLayout>
}

export default Layout
