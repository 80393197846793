// extracted by mini-css-extract-plugin
export var aside = "index-module--aside--W7g0-";
export var base = "index-module--base--Lq-V-";
export var category_list = "index-module--category_list--3CjL7";
export var header = "index-module--header--kCW8C";
export var header_and_search_form = "index-module--header_and_search_form--bEeLN";
export var latest_post_line_list = "index-module--latest_post_line_list--2TLFu";
export var main = "index-module--main--2VK1F";
export var profile_card = "index-module--profile_card--1GcG7";
export var profile_menu = "index-module--profile_menu--N8l96";
export var root = "index-module--root--3dB8r";
export var search_form = "index-module--search_form--adXCC";
export var tag_list = "index-module--tag_list--2Jcvh";