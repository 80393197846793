import React from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import IndexLayout from '@/layouts/index'
import { Pagination } from '@/components/atoms'
import { PostCardList } from '@/components/organisms'
import { SearchConnect } from '@/components/connects'
import { SEO } from '@/components/molecules'
import { MarkdownRemarkConnection } from '@types/graphql-types'

import * as styles from './index.module.scss'

interface IndexPageProps {
  data: {
    allMarkdownRemark: MarkdownRemarkConnection
    thumbnail: any
  }
  pageContext: any
}

const slice = (data, attribute) => {
  return data.reduce((acc, item) => {
    item[attribute] && acc.push(item[attribute])
    return acc
  }, [])
}

const Component: React.FC<IndexPageProps> = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark

  const location = useLocation()
  const pathname = location.pathname
  const paginationContext = { ...pageContext, pathname }
  const thumbnailPath = data.thumbnail.childImageSharp.resize.src

  return (
    <IndexLayout>
      <SEO type={"website"} thumbnailPath={thumbnailPath} />
      <SearchConnect>
        <PostCardList edges={edges} />
        <Pagination className={styles.pagination} context={paginationContext} />
      </SearchConnect>
    </IndexLayout>
  )
}

export const pageQuery = graphql`
  query IndexQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___createdOn], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            createdOn
            updatedOn
            image {
              publicURL
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          excerpt
        }
      }
    }
    thumbnail: file(relativePath: { eq: "blog-ogp-1200x630.png"}) {
      childImageSharp {
        resize(width: 1200, quality: 90) {
          src
        }
      }
    }
  }
`

export default Component
