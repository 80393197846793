import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { SiteSiteMetadataProfile } from '@types/graphql-types'

import {
  Container,
  Iframely
} from '@/components/atoms'
import {
  Header,
  Footer
} from '@/components/organisms'
import { rhythm } from '@/utils'

import * as styles from './post.module.scss'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      profile: SiteSiteMetadataProfile
    }
  }
}

const query = graphql`
  query PostLayoutQuery {
      site {
        siteMetadata {
          title
          profile {
            name
            description
          }
        }
      }
    }
`

const PostLayout: React.FC = ({ children, toc, snsShare }) => {
  const { site } = useStaticQuery(query) as StaticQueryProps
  const meta = site.siteMetadata

  return (
    <div className={styles.base}>
      <Header siteTitle={meta.title} />
      <div className={styles.root}>
        <Iframely />
        <Container>
          <div className={styles.sns_share}>
            {snsShare}
          </div>
          <div
            className={styles.main}
            style={{
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
              paddingTop: '0',
            }}
          >
            {children}
          </div>
          <div>
            <div className={styles.aside}>
              {toc}
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </div>
  )
}

export default PostLayout
