import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import {
  SearchResult
} from '@/components/organisms'

import * as styles from './SearchConnect.module.scss'

const SearchConnectClass = connectStateResults(({ searchState, children }) => {
  return (
    <>
      {searchState.query ? <SearchResult className={styles.root} /> : children}
    </>
  )
})

type Props = {
  children?: any
}

const SearchConnect: React.FC<Props> = ({ children }) => {
  return (
    <SearchConnectClass>
      {children}
    </SearchConnectClass>
  )
}

export default SearchConnect
