import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import {
  SiteSiteMetadataProfile,
  MarkdownRemarkEdge,
  MarkdownRemarkGroupConnection,
} from '@types/graphql-types'

import {
  Search,
  SearchForm
} from '@/components/atoms'
import {
  ProfileCard,
  GoTop
} from '@/components/molecules'
import {
  Header,
  Footer,
  LatestPostLineList,
  TagList,
  CategoryList,
  ProfileMenu
} from '@/components/organisms'

import { useHandleResponsible } from '@/hooks'
import * as styles from './index.module.scss'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      profile: SiteSiteMetadataProfile
    }
  }
  post: { edges: MarkdownRemarkEdge[] }
  tags: { group: MarkdownRemarkGroupConnection[] }
  category: { group: MarkdownRemarkGroupConnection[] }
  profileBackgroundImg: any
  profileCenterImg: any
}

const query = graphql`
  query IndexLayoutQuery {
    post: allMarkdownRemark(
      sort: { fields: [frontmatter___createdOn], order: DESC }
      ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                createdOn
                updatedOn
                image {
                  publicURL
                  childImageSharp {
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
              excerpt
            }
          }
        }
        tags: allMarkdownRemark {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
        category: allMarkdownRemark {
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
        }
        site {
          siteMetadata {
            title
            profile {
              name
              description
            }
          }
        }
        profileBackgroundImg: file(relativePath: { eq: "profile-cover.png"}) {
          publicURL
          childImageSharp{
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
`

type Props = {
  children?: any
  disableGoTop?: boolean
}

const IndexLayout: React.FC<Props> = ({ children, disableGoTop }) => {
  const { site, post, tags, category, profileBackgroundImg } = useStaticQuery(query) as StaticQueryProps
  const meta = site.siteMetadata
  let profile = meta.profile
  profile['backgroundImg'] = profileBackgroundImg

  const { changeDesign: changePosition } = useHandleResponsible({ breakPoint: 650 })
  const { goTopSize } = useHandleResponsible({ breakPoint: 1160 })
  const pathname = useLocation().pathname
  const isTopPage = pathname === '/' ? true : false

  return (
    <div className={styles.base}>
      {!changePosition && <Header className={styles.header} siteTitle={meta.title} isTopPage={isTopPage} />}
      <Search>
        {changePosition &&
          <div className={styles.header_and_search_form}>
            <Header className={styles.header} siteTitle={meta.title} isTopPage={isTopPage} />
            <SearchForm className={styles.search_form} />
          </div>
        }
        <div className={styles.root}>
          <div className={styles.main}>
            {children}
          </div>
          <div className={styles.aside}>
            {!changePosition && <SearchForm className={styles.search_form} />}
            <ProfileCard className={styles.profile_card} profile={profile} />
            <ProfileMenu className={styles.profile_menu} />
            <LatestPostLineList className={styles.latest_post_line_list} edges={post.edges} />
            <TagList className={styles.tag_list} group={tags.group} />
            <CategoryList className={styles.category_list} group={category.group} />
          </div>
        </div>
      </Search>
      {!disableGoTop && <GoTop size={goTopSize} />}
      <Footer />
    </div>
  )
}

export default IndexLayout
